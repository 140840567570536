import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

export default function SignIn() {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    callLoginForPro(email, password);
if (isDebugMode)     console.log({
      email: email,
      password: password,
    });
  };

  const callLoginForPro = async (email, password) => {
if (isDebugMode)     console.log("hi from callLoginForPro");
if (isDebugMode)       console.log({email: email,password: password});
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/pro_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const jsonResponse = await response.json();
if (isDebugMode)       console.log(jsonResponse);
      if (typeof jsonResponse === 'object' && jsonResponse !== null) {
        if (jsonResponse.success) {
if (isDebugMode)           console.log("success!!!!!!!!!!!!!!!!");
          navigate('/pro_trainings', { state: { proId: jsonResponse.pro_id, token: jsonResponse.token
                , training_type: jsonResponse.training_type} });
        } else {
if (isDebugMode)           console.error("wrong input");
        }
      } else {
if (isDebugMode)         console.error("Response is not JSON");
      }
    } catch (error) {
if (isDebugMode)       console.error('Error:', error);
    }
  };

  const handleForgotPassword = () => {
if (isDebugMode)     console.log("hello handleForgotPassword");
  };

  const handleSignUp = () => {
    navigate('/signup_pro');
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{ m: 1, width: 180, height: 180 ,marginTop: 8}}
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5" sx={{ marginTop: 2,color: theme.palette.primary.contrastText }}>
            כניסת מאמנים
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="אימייל"
              name="email"
              autoComplete="email"
              autoFocus
                      InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
          direction: 'ltr',
            '& .MuiOutlinedInput-root': {
               borderBottom: `2px solid ${theme.palette.primary.main}`,
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
        />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="סיסמה"
              type="password"
              id="password"
              autoComplete="current-password"
                      InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
          direction: 'ltr',
            '& .MuiOutlinedInput-root': {
               borderBottom: `2px solid ${theme.palette.primary.main}`,
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
        />
        <FormControlLabel
          sx={{ marginRight: 0 }}
          control={
            <Checkbox value="remember" color="primary" sx={{ color: theme.palette.primary.contrastText }} />
          }
          label={<Typography sx={{ color: theme.palette.primary.contrastText }}>זכור אותי</Typography>}
        />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              כניסה
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={handleForgotPassword}>
                  איפוס סיסמה
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" onClick={handleSignUp}>
                  {"אין לך חשבון? הירשם"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}
