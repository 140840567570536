import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function AddTraining() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token, proId } = currentLocation.state || {};
  const [trainers, setTrainers] = useState([]);
  const [error, setError] = useState(null);
  const [availableTrainers, setAvailableTrainers] = useState([]);
  const [trainingTypes, setTrainingTypes] = useState([]);
  const theme = useTheme(); // Using theme for styling
  const [trainingGroups, setTrainingGroups] = useState([]);

  const [formData, setFormData] = useState({
    date: '',
    time: '',
    location: '',
    trainer: '',
    description: '',
    type: '',
    maxParticipants: '1',
  });

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

useEffect(() => {
  const fetchTrainersAndGroups = async () => {
    try {
      let response;

      if (adminId) {
        response = await fetch(`${process.env.REACT_APP_BASE_URL}api/get_admin_trainers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ admin_id: adminId, token }),
        });
      } else if (proId) {
        response = await fetch(`${process.env.REACT_APP_BASE_URL}api/get_pro_trainer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pro_id: proId, token }),
        });
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        if (isDebugMode) console.log('data:', data);
        setTrainers(data.trainers);

        // Update training types
        const uniqueTrainingTypes = new Map();
        data.trainers.forEach(trainer => {
          trainer.training_type_ids.forEach((id, index) => {
            if (!uniqueTrainingTypes.has(id)) {
              uniqueTrainingTypes.set(id, trainer.training_type_labels[index]);
            }
          });
        });

        setTrainingTypes(Array.from(uniqueTrainingTypes, ([id, label]) => ({ id, label })));
        setAvailableTrainers(data.trainers);

        if (adminId) {
            // Fetch training groups
            const trainingGroups = await getTrainingGroups(adminId, token);
            setTrainingGroups(trainingGroups);
        }
      } else {
        setError('Failed to fetch trainers');
      }
    } catch (error) {
      setError(`An error occurred: ${error.message}`);
    }
  };

  fetchTrainersAndGroups();
}, [adminId, proId, token]);


const getTrainingGroups = async (adminID, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/get_training_groups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token }),
    });
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    if (jsonResponse.success) {
      return jsonResponse.groups || [];
    } else {
      if (isDebugMode) console.error("Error fetching training groups");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};


  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData(prevState => {
      const updatedState = {
        ...prevState,
        [name]: value
      };

      if (name === 'type') {
        const selectedType = parseInt(value, 10);
        if (selectedType === 1) updatedState.maxParticipants = 1;
        else if (selectedType === 2) updatedState.maxParticipants = 2;
        else updatedState.maxParticipants = '10';

        const filteredTrainers = trainers.filter(trainer =>
          trainer.training_type_ids.includes(value)
        );
        setAvailableTrainers(filteredTrainers);
      }

      return updatedState;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const endpoint = adminId === undefined ? 'api/add_pro_training' : 'api/add_training';

    fetch(process.env.REACT_APP_BASE_URL + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        admin_id: adminId,
        pro_id: proId,
        token,
        ...formData,
        ...(formData.type === '0' ? { /*year: formData.year, level: formData.level*/ } : {}) // Include year and level only if type is '0'
      })
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        if (adminId === undefined) {
          navigate('/pro_trainings', { state: { proId, token } });
        } else {
          navigate('/admin_trainings', { state: { adminId, token } });
        }
      } else {
        if (isDebugMode) console.error('Failed to add training:', data.message);
      }
    })
    .catch((error) => {
      if (isDebugMode) console.error('Error adding training:', error);
    });
  };

return (
  <Container component="main" maxWidth="xs">
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
      <Avatar
        sx={{ m: 1, width: 180, height: 180 ,marginTop: 8}}
        alt="Logo"
        src={myLogo}
      />
      <Typography
        component="h1"
        variant="h5"
        sx={{
          color: theme.palette.primary.contrastText,
          textAlign: "center",
        }}
      >
        הוספת אימון
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3}}>

        <TextField
          margin="normal"
          required
          fullWidth
          id="date"
          label="תאריך"
          name="date"
          type="date"

          value={formData.date}
          onChange={handleChange}

          InputProps={{
            style: { color: theme.palette.primary.contrastText }, // Text color
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // Label color
            shrink: true, // Ensures the label is above the field
          }}
          sx={{
            textAlign: "right", // Align text to the right
            direction: 'rtl',   // RTL direction
            '& .MuiOutlinedInput-root': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Background color
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="time"
          label="שעה"
          name="time"
          type="time"
          value={formData.time}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: { color: theme.palette.primary.contrastText }, // Text color
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // Label color
            shrink: true, // Ensures the label is above the field
          }}
          sx={{
            textAlign: "right", // Align text to the right
            direction: 'rtl',   // RTL direction
            '& .MuiOutlinedInput-root': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Background color
            },
          }}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          id="location"
          label="מיקום"
          name="location"
          value={formData.location}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: { color: theme.palette.primary.contrastText }, // Text color
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // Label color
            shrink: true, // Ensures the label is above the field
          }}
          sx={{
            textAlign: "right", // Align text to the right
            direction: 'rtl',   // RTL direction
            '& .MuiOutlinedInput-root': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Background color
            },
          }}
        />

        <InputLabel id="type-label" sx={{ color: theme.palette.primary.contrastText }}>סוג אימון</InputLabel>

<Select
  labelId="type-label"
  id="type"
  name="type"
  value={formData.type}
  onChange={handleChange}
  fullWidth
  margin="normal"
  displayEmpty
            InputProps={{
            style: { color: theme.palette.primary.contrastText }, // Text color
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // Label color
            shrink: true, // Ensures the label is above the field
          }}

  sx={{
    backgroundColor: theme.palette.buttonBackground.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.contrastText,
  }}
>
  {trainingTypes.map((type) => (
    <MenuItem key={type.id} value={type.id}>
      {type.label}
    </MenuItem>
  ))}
</Select>

        <TextField
          margin="normal"
          fullWidth
          id="maxParticipants"
          label="מס' משתתפים"
          name="maxParticipants"
          type="number"
          value={formData.maxParticipants}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: { color: theme.palette.primary.contrastText }, // Text color
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // Label color
            shrink: true, // Ensures the label is above the field
          }}
          sx={{
            textAlign: "right", // Align text to the right
            direction: 'rtl',   // RTL direction
            '& .MuiOutlinedInput-root': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Background color
            },
          }}
        />

        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="תיאור"
          name="description"
          value={formData.description}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            style: { color: theme.palette.primary.contrastText }, // Text color
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // Label color
            shrink: true, // Ensures the label is above the field
          }}
          sx={{
            textAlign: "right", // Align text to the right
            direction: 'rtl',   // RTL direction
            '& .MuiOutlinedInput-root': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Background color
            },
          }}
        />










{/* Conditionally render the dropdown for training groups */}
{formData.type === '0' && (
  <>
    <InputLabel
      id="group-select-label"
      sx={{ color: theme.palette.primary.contrastText }} // Label color
    >
      בחר קבוצה
    </InputLabel>

    <Select
      labelId="group-select-label"
      id="group"
      name="group"
      value={formData.group || ''}
      onChange={handleChange}
      fullWidth
      margin="normal"
      displayEmpty
      InputProps={{
        style: { color: theme.palette.primary.contrastText }, // Text color
      }}
      InputLabelProps={{
        style: { color: theme.palette.primary.contrastText }, // Label color
        shrink: true, // Ensures the label is above the field
      }}
      sx={{
        backgroundColor: theme.palette.buttonBackground.main,  // Background color
        borderBottom: `2px solid ${theme.palette.primary.main}`, // Bottom border
        color: theme.palette.primary.contrastText,  // Text color
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',  // No border by default
          },
          '&:hover fieldset': {
            border: 'none',  // No border on hover
          },
          '&.Mui-focused fieldset': {
            borderBottom: `4px solid ${theme.palette.secondary.main}`,  // Bottom border when focused
          },
        },
      }}
    >
      {/* Map over trainingGroups and create a MenuItem for each group */}
      {trainingGroups.map((group) => (
        <MenuItem key={group.id} value={group.id}>
          {group.label}
        </MenuItem>
      ))}
    </Select>
  </>
)}











        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          הוסף
        </Button>
      </Box>
    </Box>
  </Container>
);

}

export default AddTraining;

