import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { getTrainingType } from '../utils'; // Import utility functions
import TrainingCard from "./TrainingCard"; // Import the TrainingCard component

// Fetch active cards with lessons used
const getActiveCards = async (userId, token, navigate) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + "api/user_active_card", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: userId, token: token }),
    });
    const jsonResponse = await response.json();

    if (jsonResponse.success) {
      return jsonResponse.user_active_card.map((card) => ({
        ...card,
        lessons_left: card.lessons_count - card.lessons_used,
      }));
    } else if (jsonResponse.message === "Invalid token or user ID.") {
      navigate("/login");
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return [];
};

const ActiveCards = ({ userId, token, reload }) => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();

  // Fetch data when the component is mounted or when the reload prop changes
  useEffect(() => {
    const fetchData = async () => {
      const activeCards = await getActiveCards(userId, token, navigate);
      setCards(activeCards);
    };
    fetchData();
  }, [userId, token, navigate, reload]); // Added reload as dependency to trigger re-fetch

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2, // Spacing between cards
        justifyContent: "center",
        padding: 2,
      }}
    >
      {cards.length > 0 ? (
        cards.map((card) => (
          <Box key={card.id}>
            {/* Pass lessons_used dynamically to highlight the appropriate numbers */}
            <TrainingCard
              lessonsUsed={card.lessons_used}
              // Pass additional props for flexibility
              totalLessons={card.lessons_count}
              cardTitle={getTrainingType(card.training_type)}
              cardId={card.id}
              userId={userId}
              token={token}
            />
          </Box>
        ))
      ) : (
        <Typography>No active cards available.</Typography>
      )}
    </Box>
  );
};

export default ActiveCards;
