import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import myLogo from '../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

export default function SignIn() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [resetMessage, setResetMessage] = React.useState('');
  const theme = useTheme();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    // Client-side validation
    if (!email || !password) {
      setEmailError(email ? '' : 'Email is required');
      setPasswordError(password ? '' : 'Password is required');
      return;
    }

    callLoginForAdmin(email, password);

    if (isDebugMode) console.log({ email, password });
  };

  const callLoginForAdmin = async (email, password) => {
    if (isDebugMode) console.log("hi from callLoginForAdmin");

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const jsonResponse = await response.json();

      if (isDebugMode) console.log(jsonResponse);

      if (jsonResponse.success) {
        navigate('/admin_trainings', { state: { adminId: jsonResponse.admin_id, token: jsonResponse.token } });
      } else {
        if (isDebugMode) console.error("wrong input");
        setEmailError('Invalid email or password');
        setPasswordError(''); // Clear password error on invalid login
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
      setEmailError('An error occurred. Please try again.');
    }
  };

  const handleForgotPassword = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setResetMessage('');
  };

  const handleSendResetLink = async () => {
    if (!email) {
      setResetMessage('Please enter your email address.');
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/reset_admin_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const jsonResponse = await response.json();

      if (jsonResponse.success) {
        if (isDebugMode) console.log("Reset link sent to email");
        setResetMessage('A password reset link has been sent to your email.');
      } else {
        if (isDebugMode) console.error("Failed to send reset link");
        setResetMessage('Failed to send reset link. Please try again.');
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
      setResetMessage('An error occurred. Please try again.');
    }
  };

  const handleSignUp = () => {
    navigate('/signup_admin');
  };

return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{ m: 1, width: 180, height: 180 ,marginTop: 8}}
          alt="Logo"
          src={myLogo}
        />
        <Typography
          component="h1"
          variant="h5"
          sx={{ color: theme.palette.primary.contrastText }} // Text color based on primary contrast
        >
          כניסת מנהל
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="אימייל"
            name="email"
            autoComplete="email"
            autoFocus
            error={Boolean(emailError)}
            helperText={emailError}
            InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="סיסמה"
            type="password"
            id="password"
            autoComplete="current-password"
            error={Boolean(passwordError)}
            helperText={passwordError}
            InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
          />
            <FormControlLabel
              sx={{ marginRight: 0, color: theme.palette.primary.main}}
              control={
                <Checkbox value="remember" color="primary" sx={{ color: theme.palette.primary.contrastText }} />
              }
              label={<Typography sx={{ color: theme.palette.primary.contrastText }}>זכור אותי</Typography>}
            />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: theme.palette.primary.main, // Button background color
              '&:hover': {
                bgcolor: theme.palette.primary.main, // Hover color
              },
            }}
          >
            כניסה
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={handleForgotPassword}>
                איפוס סיסמה
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={handleSignUp}>
                {"הירשם כמנהל"}
              </Link>
            </Grid>
          </Grid>
        </Box>
        {resetMessage && <Typography variant="body2" color="error" align="center">{resetMessage}</Typography>}
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
מילל איפוס סיסמה ישלח במידה והחשבון אכן קיים במערכת.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reset-email"
            label="email "
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>בטל</Button>
          <Button onClick={handleSendResetLink}>שלח</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
