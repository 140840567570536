import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useLocation as useRouterLocation } from 'react-router-dom';
import ActiveCards from './ActiveCards';
import PreviousCards from './PreviousCards';
import NavBar from '../NavBar'; // Import the NavBar component
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure
import { useTheme } from '@mui/material/styles'; // Import useTheme
import PaymentDialog from './PaymentDialog'; // Import the PaymentDialog component
import Button from '@mui/material/Button';

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function Cards() {
  const currentLocation = useRouterLocation();
  const { userId, token } = currentLocation.state || {}; // Extract userId and token
  const [tabIndex, setTabIndex] = React.useState(2); // Initialize the tab index
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false); // State for opening the PaymentDialog
  const [reloadCards, setReloadCards] = useState(false); // State to trigger reload of ActiveCards
  const theme = useTheme(); // Access the theme for colors

  React.useEffect(() => {
    if (isDebugMode) console.log('User ID:', userId);
    if (isDebugMode) console.log('Token:', token);
  }, [userId, token]);

  const handleOpenPaymentDialog = () => {
    setOpenPaymentDialog(true); // Open the PaymentDialog
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false); // Close the PaymentDialog
    setReloadCards(prev => !prev); // Toggle the reload state to trigger ActiveCards re-render
  };

  return (
    <Container component="main" maxWidth="md" className="users-page" sx={{ flex: 1, overflowY: 'auto', paddingBottom: 8 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
        <Avatar
          sx={{ m: 1, width: 180, height: 180, marginTop: 8 }}
          alt="Logo"
          src={myLogo}
        />
        {/* כפתור תשלום שמופיע כאן */}
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h6"
            align="center"
            sx={{
              color: theme.palette.primary.contrastText, // Set text color to contrastText
              marginBottom: 2, // Add margin bottom for spacing
            }}
          >
            _______________
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPaymentDialog}
            sx={{
              padding: '12px 24px',
              fontSize: '16px',
              borderRadius: '8px',
            }}
          >
            רכישת כרטיסייה\אימונים
          </Button>
        </Box>

        <Typography component="h1" variant="h5"></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', padding: 4 }}>
          <Box sx={{ mb: 4, width: '100%', maxWidth: 600 }}>
            <Typography variant="h6" align="center"></Typography>
            {/* Pass reloadCards state to ActiveCards to trigger re-fetch */}
            <ActiveCards userId={userId} token={token} reload={reloadCards} />
          </Box>
          <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
            <Typography
              variant="h6"
              align="right"
              sx={{
                color: theme.palette.primary.contrastText, // Set text color to contrastText
                marginTop: 6, // Add margin bottom for spacing
                marginRight: 4, // Add margin right for spacing
              }}
            >
              היסטוריית כרטיסיות
            </Typography>
            <PreviousCards userId={userId} token={token} />
          </Box>
        </Box>
      </Box>

      {/* דיאלוג תשלום - Pass userId and token to PaymentDialog */}
      <PaymentDialog
        open={openPaymentDialog}
        onClose={handleClosePaymentDialog}
        userId={userId} // Pass userId to PaymentDialog
        token={token}   // Pass token to PaymentDialog
      />
      <NavBar userId={userId} token={token} tabIndex={tabIndex} setTabIndex={setTabIndex} /> {/* Use the NavBar */}
    </Container>
  );
}

export default Cards;
