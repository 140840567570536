import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTheme } from '@mui/material/styles'; // Import useTheme

import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

export default function SignUp() {
  const navigate = useNavigate();
  const theme = useTheme();

  // Define state variables for the form fields
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get('name');
    const phone = data.get('phone');
    const email = data.get('email');
    const password = data.get('password');

    callSignUp(name, phone, email, password);
    if (isDebugMode) console.log({ name, phone, email, password });
  };

  const callSignUp = async (name, phone, email, password) => {
    if (isDebugMode) console.log("hi from callSignUp");
    if (isDebugMode) console.log({ name, phone, email, password });
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, phone, email, password }),
      });
      const jsonResponse = await response.json();
      if (isDebugMode) console.log(jsonResponse);
      if (jsonResponse.success) {
        navigate('/check_email');
      } else {
        if (isDebugMode) console.error("Sign Up failed");
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
    }
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{ m: 1, width: 180, height: 180 ,marginTop: 8}}
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5" sx={{ marginTop: 8,color: 'primary.contrastText' }}>
            רישום למערכת
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="שם מלא"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}  // State variable `name` used here
              onChange={(e) => setName(e.target.value)}  // Updating `name` with `setName`
              InputProps={{
                style: { color: theme.palette.primary.contrastText }, // Text in white
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.contrastText }, // Label in white
              }}
              sx={{
                direction: 'ltr',
                '& .MuiOutlinedInput-root': {
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    borderBottom: `4px solid ${theme.palette.secondary.main}`,  // Set border to 4px when focused
                  },
                  backgroundColor: theme.palette.buttonBackground.main,  // Background color
                },
                '& .MuiFormHelperText-root': {
                  border: 'none',
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="טלפון"
              name="phone"
              autoComplete="phone"
              value={phone}  // State variable `phone` used here
              onChange={(e) => setPhone(e.target.value)}  // Updating `phone` with `setPhone`
              InputProps={{
                style: { color: theme.palette.primary.contrastText }, // Text in white
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.contrastText }, // Label in white
              }}
              sx={{
                direction: 'ltr',
                '& .MuiOutlinedInput-root': {
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    borderBottom: `4px solid ${theme.palette.secondary.main}`,  // Set border to 4px when focused
                  },
                  backgroundColor: theme.palette.buttonBackground.main,  // Background color
                },
                '& .MuiFormHelperText-root': {
                  border: 'none',
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="כתובת מייל"
              name="email"
              autoComplete="email"
              value={email}  // State variable `email` used here
              onChange={(e) => setEmail(e.target.value)}  // Updating `email` with `setEmail`
              InputProps={{
                style: { color: theme.palette.primary.contrastText }, // Text in white
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.contrastText }, // Label in white
              }}
              sx={{
                direction: 'ltr',
                '& .MuiOutlinedInput-root': {
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    borderBottom: `4px solid ${theme.palette.secondary.main}`,  // Set border to 4px when focused
                  },
                  backgroundColor: theme.palette.buttonBackground.main,  // Background color
                },
                '& .MuiFormHelperText-root': {
                  border: 'none',
                },
              }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="סיסמה"
              name="password"
              type="password"
              autoComplete="current-password"
              value={password}  // State variable `password` used here
              onChange={(e) => setPassword(e.target.value)}  // Updating `password` with `setPassword`
              InputProps={{
                style: { color: theme.palette.primary.contrastText }, // Text in white
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.contrastText }, // Label in white
              }}
              sx={{
                direction: 'ltr',
                '& .MuiOutlinedInput-root': {
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    borderBottom: `4px solid ${theme.palette.secondary.main}`,  // Set border to 4px when focused
                  },
                  backgroundColor: theme.palette.buttonBackground.main,  // Background color
                },
                '& .MuiFormHelperText-root': {
                  border: 'none',
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
               כניסה
            </Button>
            <Grid container>
              <Grid item>
                <Button onClick={() => navigate('/login')} variant="text">
                  {"יש לך חשבון? מעבר למסך כניסה"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}
