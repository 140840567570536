import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import CardTrainings from './AdminCardTrainings';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure
import { useTheme } from '@mui/material/styles'; // Import useTheme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function AdminTrainings() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token , cardId} = currentLocation.state || {}; // Extract adminId and token
  //    navigate('/card_details', { state: { adminId, token, cardId: card.id } });
  const theme = useTheme();


  React.useEffect(() => {
    if (isDebugMode) console.log('Admin ID:', adminId);
    if (isDebugMode) console.log('Token:', token);
    if (isDebugMode) console.log('cardId:', cardId);
  }, [adminId, token, cardId]);

//  const handleTabChange = (event, newValue) => {
//    if (isDebugMode) console.log('handleTabChange', newValue);
//    switch (newValue) {
//      case 0:
//        navigate('/admin_trainings', { state: { adminId, token } });
//        break;
//      case 1:
//        navigate('/admin_cards', { state: { adminId, token } });
//        break;
//      case 2:
//        navigate('/users_info', { state: { adminId, token } });
//        break;
//      default:
//        break;
//    }
//  };

  return (
      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
          <Avatar
            sx={{ m: 1, width: 180, height: 180 ,marginTop: 8}}
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5">
            פרטי כרטיסייה
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>

            <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
              <CardTrainings adminId={adminId} token={token} cardId={cardId} />
            </Box>

          </Box>
        </Box>
      </Container>
  );
}

export default AdminTrainings;
