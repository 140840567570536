import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useTheme } from '@mui/material/styles'; // Import useTheme for access to theme

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const getUsersInfo = async (adminID, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token }),
    });
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    if (jsonResponse.success) {
      return jsonResponse.users || [];
    } else {
      if (isDebugMode) console.error("Error fetching users info");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

function AdminUsersInfo() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token } = currentLocation.state || {};
  const [usersInfo, setUsersInfo] = useState([]);
  const [filter, setFilter] = useState({ name: '', phone: '', email: '' });
  const [tabIndex, setTabIndex] = useState(2);
  const theme = useTheme(); // Access the theme for color adjustments

  const fetchData = async () => {
    const users = await getUsersInfo(adminId, token);
    setUsersInfo(users);
  };

  useEffect(() => {
    fetchData();
  }, [adminId, token]);

  const handleTabChange = (event, newValue) => {
    if (isDebugMode) console.log('handleTabChange', newValue);

  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const handleRowClick = (userId) => {
    navigate('/admin_user_details', { state: { adminId, token, userId } });
  };

  return (
      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
          <Avatar
            sx={{ m: 1, width: 180, height: 180 ,marginTop: 8}}
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5" sx={{ marginTop: 2, color: theme.palette.buttonBackground.contrastText}}>
            פרטי משתמשים
          </Typography>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="navigation tabs"
            sx={{ marginBottom: 4 }}
          >

          </Tabs>
          <Box sx={{ width: '100%', maxWidth: 600 }}>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField
                label="סנן לפי שם "
                variant="outlined"
                size="small"
                name="name"
                value={filter.name}
                onChange={handleFilterChange}
                            InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
              />
              <TextField
                label="סנן לפי מייל"
                variant="outlined"
                size="small"
                name="email"
                value={filter.email}
                onChange={handleFilterChange}
                            InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
              />
              <TextField
                label="סנן לפי טלפון"
                variant="outlined"
                size="small"
                name="phone"
                value={filter.phone}
                onChange={handleFilterChange}
                            InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
              />


            </Box>
            <Box sx={{  overflowY: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'right', padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>שם</th>
                    <th style={{ textAlign: 'right', padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>מייל</th>
                    <th style={{ textAlign: 'right', padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>טלפון</th>
                  </tr>
                </thead>
                <tbody>
                  {usersInfo
                    .filter(user => {
                      const phoneMatch = user.phone.toLowerCase().includes(filter.phone.toLowerCase());
                      const emailMatch = user.email.toLowerCase().includes(filter.email.toLowerCase());
                      const nameMatch = user.name.toLowerCase().includes(filter.name.toLowerCase());
                      return nameMatch && phoneMatch && emailMatch;
                    })
                    .map(user => (
                      <tr key={user.id} onClick={() => handleRowClick(user.id)} style={{ cursor: 'pointer' }}>
                        <td style={{ padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{user.name}</td>
                        <td style={{ padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>{user.phone}</td>
                        <td style={{ padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>{user.email}</td>
                      </tr>
                    ))}

                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Container>
  );
}

export default AdminUsersInfo;
