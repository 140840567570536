import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Typography, IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

const products = [
  { name: 'כרטיסייה של 10 אימונים', price: 1600 },
  { name: 'אימון קבוצתי', price: 180 },
  { name: 'אימון פרטי', price: 400 },
  { name: 'תזונאי 3 מפגשים', price: 1200 },
  { name: 'מנטאליסט 5 מפגשים', price: 1500 },
  { name: 'מנטאליסט מפגש 1', price: 320 },
];

export default function PaymentDialog({ open, onClose, userId, token }) {
  const [quantities, setQuantities] = useState(
    products.reduce((acc, product) => ({ ...acc, [product.name]: 0 }), {})
  );
  const [totalPrice, setTotalPrice] = useState(0);

  const growPaymentRef = useRef(null);

  // This hook will load the SDK script when the component is mounted
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://cdn.meshulam.co.il/sdk/gs.min.js';
    script.onload = () => {
      console.log('Grow SDK Loaded');
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleQuantityChange = (productName, increment) => {
    setQuantities((prevQuantities) => {
      const newQuantity = prevQuantities[productName] + increment;
      const updatedQuantities = { ...prevQuantities, [productName]: Math.max(0, newQuantity) };
      calculateTotalPrice(updatedQuantities);
      return updatedQuantities;
    });
  };

  const calculateTotalPrice = (quantities) => {
    const total = products.reduce(
      (sum, product) => sum + product.price * quantities[product.name],
      0
    );
    setTotalPrice(total);
  };


  const handlePaymentResponse = async (paymentData, confirmation_number, id) => {
    // You can send the payment data back to your server or display a confirmation message
    console.log('Payment success paymentData ', paymentData);
    console.log('Payment success confirmation_number ', confirmation_number);
    console.log('Payment success id ', id);

    const updatedData = {
      ...paymentData,
      confirmation_number: confirmation_number,  // Append response_data
      id:id,
    };
    console.log(updatedData);
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/user_paid', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      const result = await response.json();
      console.log(result);

      if (result.ok) {

      }

    } catch (error) {
      console.error('שגיאה בשליחת הבקשה:', error);
    }

  };


  const handlePayment = async () => {
    const amountPaid = totalPrice;
    const data = {
      token,
      user_id: userId,
      amount_paid: amountPaid,
      group10: quantities['כרטיסייה של 10 אימונים'],
      group1: quantities['אימון קבוצתי'],
      private: quantities['אימון פרטי'],
      nutrition3: quantities['תזונאי 3 מפגשים'],
      mental5: quantities['מנטאליסט 5 מפגשים'],
      mental1: quantities['מנטאליסט מפגש 1'],
    };

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/user_pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      console.log(result);

      if (result.auth_code) {
        // Trigger Grow Payment UI with authCode
        console.log('Received auth_code, closing dialog and rendering payment options');

        // Close the dialog
//        onClose(); // Close the dialog

        // Call the function to handle the payment options
        renderPaymentOptions(data, result.auth_code, result.id);
      }

    } catch (error) {
      console.error('שגיאה בשליחת הבקשה:', error);
    }
  };

  const renderPaymentOptions = (data, authCode, id) => {
    if (window.growPayment) {
      // Create the script to load the Grow SDK and configure it dynamically
      (function () {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.meshulam.co.il/sdk/gs.min.js';
        s.onload = configureGrowSdk;
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      })();

      function configureGrowSdk() {
        let config = {
          environment: 'DEV', // or "PROD" for production
          version: 1,
          events: {
            onSuccess: (response) => {
//              alert('Success ' + response);
              //TODO call handlePayment with response.data and add it to data send to server
              console.log(response);
              console.log("authCode:", authCode);
              handlePaymentResponse(data, response.data.confirmation_number, id)
              onClose(); // Close the dialog
            },
            onFailure: (response) => {
//              alert('Failure ' + response);
              console.log(response);
            },
            onError: (response) => {
//              alert('onError ' + response);
              console.log(response);
            },
            onWalletChange: (state) => {
//              alert(state);
              console.log(state);
            },
          },
        };

        window.growPayment.init(config);

        // Call the renderPaymentOptions function after the SDK is initialized
        setTimeout(function () {
//          onClose(); // Close the dialog
          window.growPayment.renderPaymentOptions(authCode);
        }, 2000);
      }
    } else {
      console.error('Grow SDK is not initialized');
    }
  };

  const handleCancel = () => {
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={(e, reason) => reason !== 'backdropClick' && onClose()} sx={{
      '& .MuiDialog-paper': {
        backgroundColor: theme => theme.palette.dialogBackground2.main,
        border: `2px solid ${theme => theme.palette.primary.main}`,
        width: '500px',  // Set fixed width for the dialog
      },
    }}>
      <DialogTitle sx={{ color: (theme) => theme.palette.primary.contrastText }}>
        בחר סוגי אימונים\כרטיסיות
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          {products.map((product) => (
            <Grid item key={product.name}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Typography variant="body1" sx={{ color: theme => theme.palette.primary.contrastText }}>
                  {product.name}
                </Typography>
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => handleQuantityChange(product.name, -1)}
                    sx={{
                      color: theme => theme.palette.primary.main,
                      fontSize: '1.5rem',
                    }}
                  >
                    <Remove />
                  </IconButton>
                  <Typography
                    variant="body1"
                    sx={{ color: (theme) => theme.palette.primary.contrastText, display: 'inline-block' }}
                  >
                    {quantities[product.name]} ({product.price * quantities[product.name]} ₪)
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() => handleQuantityChange(product.name, 1)}
                    sx={{
                      color: theme => theme.palette.primary.main,
                      fontSize: '1.5rem',
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item>
            <Typography variant="h6" color="white">
              מחיר סופי: {totalPrice} ₪
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary" variant="outlined" style={{ color: 'white' }}>
          ביטול
        </Button>
        <Button onClick={handlePayment} color="primary" variant="contained" style={{ color: 'white' }}>
          לתשלום
        </Button>
      </DialogActions>
    </Dialog>
  );
}
