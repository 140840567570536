import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import ProActiveTrainings from '../admin/AdminActiveTrainings';
import ProPreviousTrainings from '../admin/AdminPreviousTrainings';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

// Create theme for MUI components

function ProTrainings() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { proId, token , training_type} = currentLocation.state || {}; // Extract training_type, adminId and token
  const theme = useTheme();

  React.useEffect(() => {
    if (isDebugMode) console.log('Pro ID:', proId);
    if (isDebugMode) console.log('Token:', token);
    if (isDebugMode) console.log('training_type:', training_type);
  }, [proId, token, training_type]);

//  const handleTabChange = (event, newValue) => {
//    if (isDebugMode) console.log('handleTabChange', newValue);
//    switch (newValue) {
//      case 0:
//        navigate('/admin_trainings', { state: { adminId, token } });
//        break;
//      case 1:
//        navigate('/admin_cards', { state: { adminId, token } });
//        break;
//      case 2:
//        navigate('/users_info', { state: { adminId, token } });
//        break;
//      default:
//        break;
//    }
//  };

  const handleCreateNewTraining = () => {
    navigate('/add_training', { state: { proId, token ,training_type} });
  };

  return (
      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
          <Avatar
            sx={{ m: 1, width: 180, height: 180 ,marginTop: 8}}
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5"
          sx={{
                color: theme.palette.buttonBackground.contrastText,
              }}
              >
            אימונים
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
            <Button
              variant="contained"
              onClick={handleCreateNewTraining}
              sx={{ mb: 2 }}
            >
              יצירת אימון
            </Button>
            <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
              <Typography variant="h6" align="center">אימונים פעילים</Typography>
              <ProActiveTrainings proId={proId} token={token} showAll={true}/>
            </Box>
            <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
              <Typography variant="h6" align="center">היסטוריית אימונים</Typography>
              <ProPreviousTrainings proId={proId} token={token} showAll={true}   />
            </Box>
          </Box>
        </Box>
      </Container>
  );
}

export default ProTrainings;
